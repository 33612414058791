<template>
  <div style="background: white; min-height: 750px; width: 100%;">
    <template v-if="d_selectedWdmrData && d_selectedWdmrData.id">
      <b-row style="margin: 5px;">
        <b-col sm="12" md="12">
          <strong> {{ d_selectedWdmrData.label }} </strong>
        </b-col>
      </b-row>
      <template v-if="d_selectedWdmrData.data && d_selectedWdmrData.data.general && d_selectedWdmrData.data.general.info && d_selectedWdmrData.data.general.info.list && d_selectedWdmrData.data.general.info.list.length > 0">
        <template v-for="(info, info_ind) in d_selectedWdmrData.data.general.info.list">
          <b-row v-if="info && info.explanation && info.explanation.val" style="margin: 5px;">
            <b-col sm="12" md="12">
              <div v-html="f_getInfoHtml(info.explanation.val)"></div>
            </b-col>
          </b-row>
          <b-row v-if="info && info.image && info.image.val" style="margin: 5px;">
            <b-col sm="12" md="12">
              <img :src="info.image.val" style="max-width: 75%;">
            </b-col>
          </b-row>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import GlobalService from '@/services/global';
import WdmService from '@/services/wdm';
import {
  default as DocumentTreeList
} from "@/components/widgets/DocumentTreeList";
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as router
} from '@/router';

export default {
  name: 'ShowOneDocument',
  computed: {
    ...mapGetters({})
  },
  mounted: function () {},
  data () {
    return {
      d_selectedWdmrData: ''
    };
  },
  created: function () {
    if (this.$route.query.docid !== undefined) {
      this.f_getWdmrData('wdm231_' + this.$route.query.docid);
    }
  },
  filters: {},
  methods: {
    f_getWdmrData: function (wdmr_key) {
      // Examples: wdmr_key => wdm7_1, wdm7_2, ......
      let data = { 'key': wdmr_key, 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedWdmrData = resp.data.result;
          } else {
            this.d_selectedWdmrData = '';
            console.log('errorr f_getWdmrData : ', resp.data.message);
          }
        });
    },
    f_getInfoHtml: function (txt) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^\"#\&\?]*).*/;
      let match = txt.match(regExp);
      // console.log(match);
      let videoId = '';
      if (match && match.length > 2) {
        videoId = match[2];
      }
      // console.log('videoId', videoId);
      if (videoId) {
        let replace_text = '<figure class=\"media\"><oembed url=\"https://www.youtube.com/watch?v=' + videoId + '\"></oembed></figure>';
        let iframeMarkup = '<iframe max-width="100%" height="315" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>';
        let starting_text = txt.substring(0, txt.indexOf(replace_text));
        if (starting_text.length > 300) {
          starting_text = starting_text.substring(0, 300) + '...';
        }
        return starting_text + iframeMarkup;
      } else {
        // console.log(txt)
        if (txt.length > 300) {
          return txt.substring(0, 300) + '...';
        } else {
          return txt;
        }
      }
    }
  },
  components: {
    DocumentTreeList
  }
};

</script>

